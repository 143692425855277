import { Col, Container, Row, Image} from 'react-bootstrap';
import igphoto from '../assets/ig1.jpeg';


const AboutUs = () => {
    return (

    <>
    

     <Container id="about" className="mt-2 py-4">
        <Row className="mt-5 mb-4">
        <h1 className="section-header text-center fw-bold">ABOUT US</h1> 
        </Row>
        <Row>
            <Col lg={6}  > 
                <h1 className="display-5 fw-bold mb-3 lh-1">Dr. Inna Gordin, M.D.</h1>
                <p className="lead">Dr. Inna Gordin is a board-certified Gastroenterologist, specializing in digestive disease and disorders, endoscopy, colonoscopy and capsule endoscopy. Dr. Gordin has been in private practice since 2001 and has been serving the Brooklyn community for over 20 years. Dr. Gordin is affiliated with Mount Sinai Hospital. </p>
                <p className="lead">She was trained in Internal Medicine at Beth Israel Medical Center in New York, NY and completed a Gastroenterology Fellowship at Interfaith Medical Center in Brooklyn, NY. Dr. Gordin is a diplomate of the American Board of Internal Medicine and Gastroenterology and a Fellow of American College of Gastroenterology (ACG). </p>
                <p className="lead">Dr. Gordin lives on Long Island with her husband. She loves spending time with family and has raised two beautiful children. In her free time, Dr. Gordin enjoys at-home fitness videos, biking, reading, and walking.</p>


                
            </Col>
            <Col lg={6}>
                <Image className="d-block w-100"
                src={igphoto}
                alt="Hero goes here"
                fluid rounded/>
            </Col>
        </Row>
     </Container>
    </>
    );
};

export default AboutUs; 
import { Container } from "react-bootstrap";

const Faq = () => {
    return (
<>
<Container>
<h1>FAQ info will go here</h1>
</Container>
</>

    );
};

export default Faq;
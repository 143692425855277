import { Col, Container, Row, Button} from 'react-bootstrap';
import herodoc from '../assets/HeroDoc.jpg'
import classes from './Hero.module.css'

const Hero = () => {
    return (
    <>
    <Container id="home" fluid style={{ backgroundImage: `url(${herodoc})`, height:'100vh'}} className="d-flex align-items-center bg-image">
    {/* <Container fluid style={{ backgroundImage: `url(${herodoc})`, backgroundSize: 'cover', height: 600 }} className="d-flex align-items-center"> */}
        <Container>

        
        <Row>   
            <Col lg={4}> 
                <h4 className="fw-light">Dr. Inna Gordin</h4>
                <h1 className="display-5 fw-bold ">Leading the way in Gastroentorology</h1> 
                <p className="lead"> Providing the best possible nutrition and gastroentorology care to our patients locally and virtually. 
                </p>
                <Button className={classes.button} size="lg" href="#contact">Make an appointment</Button>
            </Col>
        </Row>    
        </Container>
    </Container>


    </>
    );
};

export default Hero; 
import AboutUs from '../components/AboutUs';
import Conditions from '../components/Conditions';
import Contact from '../components/ContactUs';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Treatments from '../components/Treatments';

const Home = () => {
  return (
    <>
      <Hero />
      <AboutUs />
      <Conditions />
      <Treatments />
      <Contact />
      <Footer />
    </>
  );
};

export default Home;

import { Container } from "react-bootstrap";

const Preparation = () => {
    return (
<>
<Container>
<h1>Preparation info will go here</h1>
</Container>
</>

    );
};

export default Preparation;
import { Container } from "react-bootstrap";

const insurance = () => {
    return (
<>
<Container>
<h1>Insurances pages</h1>
</Container>
</>

    );
};

export default insurance;
import { Col, Container, Row, Nav, Tab } from "react-bootstrap";
import classes from './Treatments.module.css'

const Treatments = () => {
  return (
    <Container id="treatments" className="mt-2 py-4">
      <Row className="py-5">
        <h1 className="section-header text-center fw-bold">
          TREATMENTS AND PROCEDURES
        </h1>
        <p className="text-center lead">
          We offer a comprehensive range of treatments and procedures designed to diagnose and manage digestive health conditions. 
        </p>
      </Row>
      <Tab.Container defaultActiveKey="first">
        <Row className="gy-4">
          <Col lg={3}>
            <Nav
              variant="pills"
              defaultActiveKey="/home"
              className="flex-column"
            >
              <Nav.Item className={classes['treatment-select']}>
                <Nav.Link eventKey="first" >
                  Upper Endoscopy (EGD)
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={classes['treatment-select']}>
                <Nav.Link eventKey="second">Colonoscopy</Nav.Link>
              </Nav.Item>
              <Nav.Item className={classes['treatment-select']}>
                <Nav.Link eventKey="third">Capsule Endoscopy</Nav.Link>
              </Nav.Item>
              <Nav.Item className={classes['treatment-select']}>
                <Nav.Link eventKey="fourth">Intolerance Tests</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col lg={9}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <h1 className="display-6 treatment-header">
                  Upper Endoscopy (EGD)
                </h1>
                <p>
                  Upper GI endoscopy is a procedure in which a doctor uses an
                  endoscope a flexible tube with a camera to see the lining of
                  your upper GI tract. One of our providers performs the
                  procedure, most often while you receive light sedation to help
                  you relax. Also call the procedure endoscopy, upper endoscopy,
                  EGD or esophagogastroduodenoscopy. Our Physicians use upper GI
                  endoscopy to help diagnose and treat symptoms and conditions
                  that affect the esophagus, stomach, and upper intestine or
                  duodenum.
                </p>
                <p>
                  Upper GI endoscopy can check for damage after a person eats or
                  drinks harmful chemicals. During upper GI endoscopy, a
                  physician obtains biopsies by passing an instrument through
                  the endoscope to obtain a small piece of tissue for testing.
                  Biopsies are needed to diagnose conditions such a cancer, celiab disease, and gastritis.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <h1 className="display-6 treatment-header">Colonoscopy</h1>
                <p>
                  Colonoscopy is a procedure in which our physicians use a
                  colonoscope or scope, to look inside your rectum and colon.
                  Colonoscopy can show irritated and swollen tissue, ulcers,
                  polyps, and cancer. A colonoscopy can help find the cause of
                  symptoms, such as bleeding from your anus changes in your
                  bowel activity, such as diarrhea pain in your abdomen
                  unexplained weight loss
                </p>
                <p>
                  Our physicians also use colonoscopy as a screening tool for
                  colon polyps and cancer. Screening is testing for diseases
                  when you have no symptoms. Screening may find diseases at an
                  early stage, when a doctor has a better chance of curing the
                  disease.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <h1 className="display-6 treatment-header">
                  Capsule Endoscopy
                </h1>
                <p>
                Capsule endoscopy involves swallowing a small, pill-sized camera that takes thousands of images of the small intestine. 
                This minimally invasive procedure helps diagnose conditions such as Crohn’s disease, small intestine tumors, and unexplained bleeding.
                </p>
              </Tab.Pane>
              <Tab.Pane eventKey="fourth">
                <h1 className="display-6 treatment-header">
                  Intolerance Tests
                </h1>
                <p>
                Food intolerances can contribute to chronic digestive issues like bloating, gas, diarrhea, and stomach pain. We offer specialized intolerance tests to identify sensitivities to common foods such as lactose, gluten, or specific proteins. Based on the results, we provide tailored dietary guidance and treatment plans to help alleviate symptoms and improve digestive health.
                </p>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </Container>
  );
};

export default Treatments;

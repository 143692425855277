import { Container, Row } from "react-bootstrap";
import {
  faDroplet,
  faFireFlameCurved,
  faHeadSideCough,
  faFaceFrownOpen,
  faToilet,
  faCapsules,
} from "@fortawesome/free-solid-svg-icons";
import ConditionItem from "./ConditionItem";

const conditions_info = [
  {
    icon: faFaceFrownOpen,
    title: "Abdominal pain",
    description:
      "Abdominal pain has many causes, and can occur in organs of the digestive tract. If you are experiencing chronic, repetitive, or severe abdominal pain, it is important to get a diagnosis as soon as possible.",
    id: 1,
  },
  {
    icon: faFireFlameCurved,
    title: "Acid reflux and heartburn",
    description:
      "Acid reflux, or heartburn, is the backward flow of stomach acid into the esophagus. If you have acid reflux on a regular basis, even a few times a month, you should be evaluated to make sure your esophagus is healthy. Acid reflux may require more effective treatment than over-the-counter medication.",
    id: 2,
  },
  {
    icon: faHeadSideCough,
    title: "Belching, bloating, and gas",
    description:
      "Belching, bloating, and gas are common digestive issues that can cause discomfort and embarrassment, but they are often treatable. While these symptoms can occur occasionally in healthy individuals, frequent or severe cases may indicate an underlying digestive disorder.",
    id: 3,
  },
  {
    icon: faToilet,
    title: "Constipation and diarrhea",
    description:
      "Constipation and diarrhea are two of the most common digestive complaints that can significantly impact quality of life. While these conditions can occur occasionally due to changes in diet or stress, chronic or severe cases may be signs of an underlying gastrointestinal issue.",
    id: 4,
  },
  {
    icon: faDroplet,
    title: "Rectal bleeding",
    description:
      "Rectal bleeding refers to the presence of blood in or around the stool, typically noticed during bowel movements. While it can be alarming, rectal bleeding can result from a variety of causes—some mild and others more serious.",
    id: 5,
  },
  {
    icon: faCapsules,
    title: "Difficulty swallowing",
    description:
      "Difficulty swallowing, also known as dysphagia, is a condition that affects the ability to move food or liquids from the mouth to the stomach. While occasional swallowing issues are common, persistent or painful difficulty swallowing may indicate an underlying medical condition that requires evaluation and treatment.",
    id: 6,
  },
];

const Conditions = () => {
  return (
    <Container id="conditions" className="mt-2 py-4">
      <Row className="mt-5">
        <h1 className="section-header text-center fw-bold">CONDITIONS</h1>

        <p className="text-center lead">
          Below you can find some of the common conditions Dr. Gordin treats. If you are experiencing any of these be sure to make an appoinment today. 
        </p>
      </Row>
      <Row>
        {conditions_info.map((condition) => (
          <ConditionItem
            title={condition.title}
            icon={condition.icon}
            description={condition.description}
            key={condition.id}
          />
        ))}
      </Row>
    </Container>
  );
};

export default Conditions;

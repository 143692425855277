import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col } from "react-bootstrap";
import classes from './ConditionItem.module.css'

const ConditionItem = (props) => {
  return (
    <Col lg={4} md={6} className="d-flex align-items-stretch mt-4">
      <div className={classes['icon-box']}>
        <div className={classes.icon}>
          <FontAwesomeIcon icon={props.icon} className={classes['icon-inner']} />
        </div>
        <h4>{props.title}</h4>
        <p>{props.description}</p>
      </div>
    </Col>
  );
};

export default ConditionItem;
